import styled from 'styled-components';
import {FaInfoCircle} from 'react-icons/fa';

export const PricingSection = styled.div`
  padding: 100px 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F2F3F9;
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
  color: black;
  font-size: 48px;
  margin-bottom: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PricingCard = styled.div`
  background: white;
  width: 640px;
  height: 720px;
  text-decoration: none;
  border-radius: 16px;
  border: 2px solid #E8E8E8; 

  &:nth-child(2) {
    margin: 24px;
  }

  @media screen and (max-width: 960px) {
    width: 90%;

    &:hover {
      transform: none;
    }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 720px;
  padding: 20px;
  align-items: center;
  color: black;
`;

export const Img = styled.img`
padding-top: 20px;
padding-right: 0;
border: 0;
max-width: 100%;
display: inline-block;
max-height: 80px;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 24px;
`;

export const PricingCardCost = styled.h4`
  font-size: 40px;
`;

export const PricingCardLengthFree = styled.p`
  font-size: 14px;
  color: white;
  margin-bottom: 24px;
`;

export const AlertIcon= styled(FaInfoCircle)`
margin: 0.8 rem;
max-width: 20px;
display: flex;
`;

export const PricingCardObj = styled.h5`
  margin-bottom: 15px;
  font-size: 18px;
  align-items: center;
  font-weight: 400;
`;

export const PricingCardAlert = styled.h5`
  margin-bottom: 15px;
  font-size: 18px;
  justify-self: flex-start;
  display: flex;
  align-items: center;
`;

export const PricingCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const PricingCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a9b3c1;
`;

export const PricingCardFeature = styled.li`
  margin-bottom: 12px;
  text-align: center;
`;
