import styled from 'styled-components';

export const InfoSec = styled.div`
color: #fff;
padding: 100px 0;
background: ${({ lightBg }) => (lightBg ? '#fff' : '#101522')};
`;


export const Heading = styled.header`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
color: black;
font-family: 'Poppins', sans-serif;
font-weight: bold;
`;

export const Img = styled.img`
padding-top: 20px;
padding-right: 0;
border: 0;
max-width: 100%;
display: inline-block;
max-height: 100%;
`;