import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const InfoSec = styled.div`
color: #fff;
padding: 100px 0;
background: ${({lightBg}) => (lightBg ? '#fff' : '#101522')};
`;

export const InfoRow = styled.div`
display: flex;
margin: 0 -15px -15px -15px;
flex-wrap: wrap;
align-items: center;
height: 600px;
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
flex: 1;
max-width: 100%;
flex-basis: 30%;

@media screen and (max-width: 768px){
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
}
`;

export const InfoColumn2 = styled.div`
margin-bottom: 15px;
padding-right: 0px;
padding-left: 40px;
flex: 1;
max-width: 100%;
flex-basis: 70%;

@media screen and (max-width: 768px){
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
}
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top:0;
padding-bottom: 60px;

@media screen and (max-width: 760px) {
    padding-bottom: 65px;
}
`;

export const Heading = styled.header`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
color: black;
font-family: 'Poppins', sans-serif;
font-weight: bold;
`;

export const Subtitle = styled.p`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color : ${({lightTextDesc})=> (lightTextDesc? '#a9b3c1' : '#1c2237')};
`;

export const ImgWrapper = styled.div`
max-width: 100%;
display: flex;
justify-content: ${({start}) => (start ? 
    'flex-start' : 'flex-end')};
`;

export const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 100%;
`;


export const InfoBtnLink= styled(Link)`
display: flex;
justify-content: none;
align-items: none;
text-decoration: none;
padding: 8px 5px;
height: 100%;
width: 100%;
border: none; 
outline: none;
`;

export const InfoLink = styled.a`

`;