import React, { useState } from 'react';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  AlertIcon,
  PricingCardAlert,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
  Img,
  PricingCardObj,
} from './Pricing.elements';
import { MONTHLY_PLAN, YEARLY_PLAN } from '../constants/plans';

function Pricing() {
  const [activeTab, setActiveTab] = useState('Mensuales'); // Estado para el tab activo

  const plans = activeTab === 'Mensuales' ? MONTHLY_PLAN : YEARLY_PLAN;

  return (
    <>
      <div id="planesSection" />
      <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
        <PricingSection>
          <PricingWrapper>
            <PricingHeading>Planes</PricingHeading>

            {/* Controles de Tabs */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', borderRadius: '5px', padding: '5px', backgroundColor: '#E6E6E6', }}>
              <button
                onClick={() => setActiveTab('Mensuales')}
                style={{
                  padding: '10px 20px',
                  cursor: 'pointer',
                  // border: activeTab === 'Mensuales' ? '1px solid black' : 'none',
                  border: 'none',
                  backgroundColor: activeTab === 'Mensuales' ? 'white' : '#E6E6E6',
                  borderRadius: '5px',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '16px',
                  lineHeight:'24px',
                  fontWeight :activeTab === 'Mensuales' ? 'bold':'normal'
                }}
              >
                Mensual
              </button>
              <button
                onClick={() => setActiveTab('Anuales')}
                style={{
                  borderRadius: '5px',
                  padding: '10px 20px',
                  cursor: 'pointer',
                  // border: activeTab === 'Anuales' ? '1px solid black' : 'none',
                  border: 'none',
                  backgroundColor: activeTab === 'Anuales' ? 'white' : '#E6E6E6' ,
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '16px',
                  lineHeight:'24px',
                  fontWeight :activeTab === 'Anuales' ? 'bold':'normal'
                }}
              >
                Anual
              </button>
            </div>

            {/* Renderizar los planes */}
            <PricingContainer>
              {plans.map((plan) => (
                <PricingCard key={plan.id}>
                  <PricingCardInfo>
                    <PricingCardIcon>
                      <Img src={plan.icon} alt={`Kusina Plan ${plan.plan}`} />
                    </PricingCardIcon>
                    <PricingCardPlan>{plan.plan}</PricingCardPlan>
                    <PricingCardCost>{plan.cost}</PricingCardCost>
                    <PricingCardLength>{plan.length}</PricingCardLength>
                    <PricingCardObj>{plan.description}</PricingCardObj>
                    <PricingCardAlert>
                      <AlertIcon color="black" /> &nbsp; {plan.alert}
                    </PricingCardAlert>
                    <hr style={{ color: '#DEDEDE', width: 250 }} />
                    <PricingCardFeatures>
                      {plan.features.map((feature, index) => (
                        <PricingCardFeature key={index}>{feature}</PricingCardFeature>
                      ))}
                    </PricingCardFeatures>
                  </PricingCardInfo>
                </PricingCard>
              ))}
            </PricingContainer>
          </PricingWrapper>
        </PricingSection>
      </IconContext.Provider>
    </>
  );
}

export default Pricing;