export const homeObjOne = {
    primary: true,
    primary2: true,
    lightBg: true,
    imgStartg: '',
    lightTopLine: true,
    lightTextDesc: true,
    description: 'Gestione la información y pedidos de su restaurante en un solo lugar',
    headline: 'Sistema para la Gestión de Restaurantes',
    lightText: true,
    img: require('../../images/pantalla-kusina-restaurantes.png'),
    alt:'Kusina Restaurantes',
    start: ''
}
