import styled from 'styled-components';

export const PrivacySec = styled.div`
color: #fff;
padding: 160px 0;
background: #fff;
`;

export const PrivacySection = styled.div`
color: #fff;
padding: 25px 0;
background: #fff;
`;

export const Heading = styled.h1`
margin-bottom: 10px;
font-size: 48px;
line-height: 1.1;
color: black;
font-family: 'Poppins', sans-serif;
font-weight: bold;
`;

export const Subtitle = styled.h2`
max-width: 440px;
margin-bottom: 10px;
font-size: 18px;
line-height: 24px;
font-family: 'Inter', sans-serif;
color : black;
`;

export const Subtitleh3 = styled.h3`
max-width: 440px;
margin-bottom: 10px;
font-size: 16px;
line-height: 24px;
font-family: 'Inter', sans-serif;
color : black;
`;


export const Text = styled.p`
max-width: auto;
margin-bottom: 10px;
font-size: 16px;
line-height: 24px;
font-family: 'Inter', sans-serif;
color : black;
`;

export const Subtext = styled.p`
max-width: auto;
margin-bottom: 10px;
font-size: 12px;
line-height: 24px;
font-family: 'Inter', sans-serif;
color : black;
`;

export const Lista = styled.ul`
max-width: auto;
margin-left: 30px;
color: black;
`;

export const ListaItem = styled.li`
`;

