import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}
`;

export const Container = styled.div`
z-index:1;
width: 100%;
max-width: 1400px;
margin-right: auto;
margin-left: auto;
padding-right: 50px;
padding-left: 50px;

@media screen and (max-width: 991px){
    padding-right: 30px;
    padding-left: 30px;    
}
`;

export const Button = styled.button`
border-radius: 16px;
background: ${({ primary }) => (primary ? 'white' : '#0467FB')};
white-space: nowrap;
padding: ${({ big }) => (big ? '15px 20px' : '15px 20px')};
color: #1410DF;
font-size: ${({ fontBig }) => (fontBig ? '18px' : '18px')};
outline: none;
border: 2px solid #1410DF; 
cursor: pointer;
font-weight: bold;

&:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #1410DF;
    background: ${({ primary }) => (primary ? '#fff' : '#4B59F7')};
}

@media screen and (max-width: 960px){
    width: 100%;
}
`;

export const Button2 = styled.button`
border-radius: 16px;
background: #1410DF;
white-space: nowrap;
padding: ${({ big }) => (big ? '15px 20px' : '15px 20px')};
color: white;
font-size: ${({ fontBig }) => (fontBig ? '18px' : '18px')};
outline: none;
border: 2px solid #1410DF; 
cursor: pointer;
font-weight: bold;

&:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #fff;
    background: ${({ primary }) => (primary ? '#1410DF' : '#1410DF')};
}

@media screen and (max-width: 960px){
    width: 100%;
}
`;

export const Button3 = styled.button`
border-radius: 16px;
background: #fff;
white-space: nowrap;
padding: ${({ big }) => (big ? '15px 20px' : '15px 20px')};
color:  #1410DF;
font-size: ${({ fontBig }) => (fontBig ? '18px' : '18px')};
outline: none;
border: 2px solid #1410DF; 
cursor: pointer;
font-weight: bold;

&:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #1410DF;
    background: ${({ primary }) => (primary ? '#fff' : '#fff')};
}

@media screen and (max-width: 960px){
    width: 100%;
}
`;

export default GlobalStyle