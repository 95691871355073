 import styled from 'styled-components';
 import {Link} from 'react-router-dom';
 import {Container} from '../../../globalStyles';

 export const Nav= styled.nav`
 background: white;
 height: 80px;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 1.2rem;
 position: sticky;
 top: 0;
 z-index: 999;
 `;

 export const ImgWrapper = styled.div`
 max-width: 280px;
 display: flex;
 `;
 
 export const Img = styled.img`
 padding-top: 20px;
 padding-right: 0;
 border: 0;
 max-width: 100%;
 display: inline-block;
 max-height: 74.7px;
 `;
 
 export const NavbarContainer= styled(Container)`
 display: flex;
 justify-content: space-between;
 height: 100px;
 ${Container}
 `;

 export const MobileIcon= styled.div`
 display: none;

    @media screen and (max-width: 960px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;

@media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({click})=> (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease;
    background: white;
}
`;

export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;
font-weight: bold;

&:hover {
    border-bottom: 2px solid #1410DF;
}

@media screen and (max-width: 960px){
    width: 100%;

    &:hover {
        border: none;
    }
}
`;

export const NavLinks = styled(Link)`
color: black;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem 1rem;
height: 100%;

@media screen and (max-width: 960px){
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
        color: #1410DF;
        transition: all 0.3s ease;
    }
}
`;

export const NavItemBtn = styled.li`
@media screen and (max-width: 960px){
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
}
`;

export const NavLink = styled.a`
color: #fff;
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
padding: 8px 5px;
height: 100%;
width: 100%;
border: none; 
outline: none;
`;