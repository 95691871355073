import React from 'react';
import { Button3} from '../../../globalStyles';
import ReactGA from 'react-ga4';
import {BannerSec,
    BannerContainer,
    BannerHeading,
    BannerMenu,
    BannerLink} from './Banner.elements';

const Banner = () => {

  /*G.A.*/
  const onClickBanner=()=>{
    ReactGA.event({
      action:'playstore_accion',
      category:'download_category',
      label:'download',
      value:'2'
    })
  }
  /**/

  return (
    <>
        <BannerSec>
            <BannerContainer>
                <BannerHeading>Conoce los beneficios para nuevos usuarios</BannerHeading>
                <BannerMenu>
                    <BannerLink
                       href={
                        'https://wa.me/51973834813?text=Hola, tengo un negocio de comida y quiero mas información sobre su sistema.'
                      }
                      rel='noopener noreferrer'
                      target='_blank'
                      aria-label='Whatsapp'
                    >
                        <Button3 fontBig primary onClick={onClickBanner}>
                             Conocer mis beneficios
                        </Button3>
                    </BannerLink>
                </BannerMenu>
            </BannerContainer>
        </BannerSec>
    </>
  )
}

export default Banner
