import React from 'react';
import { homeObjOne } from './Data';
import { HomeNavbar, HomeFooter, HomeInfoSection, HomePricing, HomeBanner, QuestionSection } from '../../components';

const Home = () => {
  return (
    <>
      <HomeNavbar />
      <HomeInfoSection {...homeObjOne} />
      <HomeBanner />
      <HomePricing />
      <QuestionSection />
      {/*<HomeAnfitriones />*/}
      <HomeFooter />
    </>
  )
}

export default Home
