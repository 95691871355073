import styled from 'styled-components';
import {Container} from '../../../globalStyles';

export const BannerSec= styled.div`
color: #1410DF;
padding: 20px 0;
background-color: #1410DF;
`;

export const ImgWrapper = styled.div`
max-width: 300px;
display: flex;
`;

export const Img = styled.img`
padding-top: 20px;
padding-right: 0;
border: 0;
max-width: 100%;
display: inline-block;
max-height: 80px;
`;

export const BannerContainer= styled(Container)`
display: flex;
justify-content: space-between;
align-items: center;
text-align: center;
height: 100px;
${Container}

`;

export const BannerHeading = styled.h2`
  color: white;
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  font-size: 36px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;

  @media screen and (max-width: 960px) {
   display: none;
   color: #1410DF;
}
`;

export const BannerMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;

@media screen and (max-width: 960px) {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   opacity: 1;
}
`;

export const BannerLink = styled.a`
  color: #fff;
`;
