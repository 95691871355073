import React from 'react';
import {FaFacebook,FaWhatsapp,FaYoutube} from 'react-icons/fa';
import footerimg from '../../../images/footer-kusina-gray.png';
import {Container} from '../../../globalStyles';
import {FooterContainer, 
  FooterRow,
  FooterColumn,
  TextWrapper,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
SocialMedia,
SocialMediaWrap,
WebsiteRights,
SocialIcons,
SocialIconLink
} from './Footer.elements';

function Footer() {
  return (
    <div>
      <FooterContainer>
          <Container>
            <FooterRow imgStart=''>
                  <FooterColumn>
                      <TextWrapper>
                          <Heading lightText='true'>¿Tiene alguna duda?</Heading>
                          <Subtitle lightTextDesc='true'>Puede saber más sobre nosotros o contactarnos através de nuestras redes sociales</Subtitle>
                      </TextWrapper>
                  </FooterColumn>
                  <FooterColumn>
                    <ImgWrapper>
                      <Img src={footerimg} alt='Kusina Restaurantes' />
                    </ImgWrapper>
                  </FooterColumn>
            </FooterRow>          
          <SocialMedia>
            <SocialMediaWrap>
              <WebsiteRights>KUSINA © 2024</WebsiteRights>
              <SocialIcons>
                <SocialIconLink 
                href='https://www.facebook.com/people/Kusina-POS-Restaurant/61565510492615' target="_blank" 
                aria-label="Facebook">
                <FaFacebook/>         
                </SocialIconLink>
                <SocialIconLink 
                href='https://wa.me/51973834813' target="_blank" 
                aria-label="Whatsapp" rel="noopenernoreferrer">
                <FaWhatsapp/>         
                </SocialIconLink>
                <SocialIconLink 
                href='https://www.youtube.com/watch?v=UJicr7oVMRQ' target="_blank" 
                aria-label="Youtube" rel="noopenernoreferrer">
                <FaYoutube/>         
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </Container>
      </FooterContainer>
    </div>
  )
}

export default Footer