import styled from 'styled-components';

export const AnfitrionesSec = styled.div`
padding: 100px 0 140px;
display: flex;
flex-direction: column;
justify-content: center;
background: #fff;
text-align: center;
`;

export const AnfitrionesWrapper = styled.div`
align-items: center;

@media screen and (max-width: 960px) {
  align-items: center;
  margin: 0 30px;
}
`;

export const AnfitrionesHeading = styled.div`
  color: black;
  font-size: 48px;
  margin-bottom: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
`;

export const AnfitrionesCard0 = styled.div`
  background: white;
  width: 260px;
  height: 210px;
  text-decoration: none;
  border-radius: 16px;
  border: 2px solid white; 
  align-content: center;

  @media screen and (max-width: 960px) {
    width: 90%;

    &:hover {
      transform: none;
    }
  }
`;

export const AnfitrionesCard = styled.div`
  background: white;
  display: flex;
  width: 250px;
  height: 200px;
  text-decoration: none;
  border-radius: 16px;
  border: 2px solid white; 
 
  &:nth-child(2) {
    margin: 24px;
  }

  @media screen and (max-width: 960px) {
    width: 90%;

    &:hover {
      transform: none;
    }
  }
`;

export const ImgWrapper = styled.div`
max-width: 200px;
display: flex;
`;

export const Img = styled.img`
padding-top: 20px;
padding-right: 0;
border: 0;
max-width: 100%;
display: inline-block;
max-height: 190px;
`;
