import React from 'react';
import {Container} from '../../../globalStyles';
import { 
PrivacySec,
PrivacySection,
Heading,
Subtitle,
Subtitleh3,
Text,
Lista,
ListaItem
} from './PrivacyPolicy.elements';


const PrivacyPolicy = () => {
  return (
    <>
      <PrivacySec>
        <Container>

          <PrivacySection>
            <Heading >Políticas de Privacidad</Heading>
            <Subtitle><i>Última actualización: 05 de febrero de 2022</i></Subtitle>
            <Text>Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de Su información cuando utiliza el Servicio y le informa sobre Sus derechos de privacidad y cómo la ley lo protege.</Text>
            <Text>Usamos sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio, acepta la recopilación y el uso de información de acuerdo con esta Política de privacidad.</Text>
          </PrivacySection>   

          <PrivacySection>
            <Heading>Interpretación y Definiciones</Heading>
            <Subtitle>Interpretación</Subtitle>
            <Text>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.</Text>
            <Subtitle>Definiciones</Subtitle>
            <Text>A los efectos de esta Política de Privacidad:</Text>
            <Lista>
            <ListaItem>
            <Text><strong>Cuenta</strong> significa una cuenta única creada para que Usted acceda a nuestro Servicio o partes de nuestro Servicio.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Afiliado</strong> significa una entidad que controla, es controlada o está bajo el control común de una parte, donde &quot;control&quot; significa la propiedad del 50% o más de las acciones, participación accionaria u otros valores con derecho a voto para la elección de directores u otra autoridad administrativa.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Aplicación</strong> significa el programa de software proporcionado por la Compañía descargado por Usted en cualquier dispositivo electrónico, llamado Kusina</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Empresa</strong> (referido como &quot;La Empresa&quot;, &quot;Nosotros&quot;, o &quot;Nuestro&quot; en este Acuerdo) se refiere a Kusina.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>País</strong> se refiere a: Perú</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Dato Personal</strong> es cualquier información que se relaciona con un individuo identificado o identificable.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Servicio</strong> se refiere a la Aplicación.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Proveedor de Servicio</strong>significa cualquier persona física o jurídica que procesa los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas empleadas por la Empresa para facilitar el Servicio, proporcionar el Servicio en nombre de la Empresa, realizar servicios relacionados con el Servicio o ayudar a la Empresa a analizar cómo se utiliza el Servicio.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Uso de datos</strong> se refiere a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o de la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Usted</strong> significa la persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.</Text>
            </ListaItem>
            </Lista>
        </PrivacySection>
        <PrivacySection>
            <Heading>Recopilación y uso de sus datos personales</Heading>
            <Subtitle>Tipos de datos recopilados</Subtitle>
            <Subtitleh3>Información personal</Subtitleh3>
            <Text>Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede usar para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros:</Text>
            <Lista>
            <ListaItem>
            <Text>Nombre y apellido</Text>
            </ListaItem>
            <ListaItem>
            <Text>Número de teléfono</Text>
            </ListaItem>
            <ListaItem>
            <Text>Dirección, estado, provincia, código postal, ciudad</Text>
            </ListaItem>
            <ListaItem>
            <Text>Datos de Uso</Text>
            </ListaItem>
            </Lista>
            <Subtitleh3>Nombre y Apellido</Subtitleh3>
            <Text>El Nombre y Apellido se recopila cuando se realiza el registro, y es utilizado para facilitar a los usuarios Anfitriones una facil identificación del comensal el cual realizó el pedido.</Text>
            <Subtitleh3>Número de teléfono</Subtitleh3>
            <Text>El Número de teléfono se recopila cuando se realiza el registro, y es utilizado para facilitar a los usuarios Anfitriones una facil comunicación al comensal el cual realizó el pedido.</Text>
            <Subtitleh3>Dirección, estado, provincia, código postal, ciudad</Subtitleh3>
            <Text>La Dirección, estado, provincia, código postal, ciudad se recopila cuando se realiza un pedido, y es utilizado para facilitar a los usuarios Anfitriones una facil localización del comensal el cual realizó el pedido.</Text>
            <Subtitleh3>Datos de Uso</Subtitleh3>
            <Text>Los Datos de uso se recopilan automáticamente cuando se utiliza el Servicio.</Text>
            <Text>Los datos de uso pueden incluir información como la dirección del protocolo de Internet de su dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo que pasa en esas páginas, dispositivo único identificadores y otros datos de diagnóstico.</Text>
            <Text>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo, el tipo de navegador de Internet móvil que utiliza, identificadores únicos de dispositivos y otros datos de diagnóstico.</Text>
            <Text>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil.</Text>
            <Subtitleh3>Información recopilada durante el uso de la aplicación</Subtitleh3>
            <Text>Al utilizar Nuestra Aplicación, con el fin de proporcionar características de Nuestra Aplicación, podemos recopilar, con Su permiso previo:</Text>
            <Lista>
            <ListaItem>
            <Text>Información sobre su ubicación</Text>
            </ListaItem>
            <ListaItem>
            <Text>Imágenes y otra información de la cámara y la biblioteca de fotos de su dispositivo</Text>
            </ListaItem>
            </Lista>
            <Text>Usamos esta información para proporcionar características de Nuestro Servicio, para mejorar y personalizar Nuestro Servicio. La información se puede cargar en los servidores de la Compañía y/o en el servidor de un Proveedor de servicios o simplemente se puede almacenar en Su dispositivo.</Text>
            <Text>Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de Su dispositivo.</Text>
            <Subtitle>Uso de sus datos personales</Subtitle>
            <Text>La Compañía puede utilizar los Datos Personales para los siguientes propósitos:</Text>
            <Lista>
            <ListaItem>
            <Text><strong>Para proporcionar y mantener nuestro Servicio</strong>, including to monitor the usage of our Service.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Para administrar su cuenta:</strong> para gestionar Su registro como usuario del Servicio. Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para Usted como usuario registrado.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Para la ejecución de un contrato:</strong> el desarrollo, cumplimiento y realización del contrato de compra de los productos, artículos o servicios que haya comprado o de cualquier otro contrato con Nosotros a través del Servicio.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Para contactarte:</strong> Para contactarlo por llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como notificaciones automáticas de una aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Para proporcionarle</strong> con noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya compró o preguntó, a menos que haya optado por no recibir dicha información.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Para gestionar sus solicitudes:</strong> Para atender y gestionar Sus solicitudes hacia Nosotros.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Para transmisiones de empresas:</strong> Podemos usar Su información para evaluar o realizar una fusión, desinversión, reestructuración, reorganización, disolución u otra venta o transferencia de algunos o todos Nuestros activos, ya sea como una empresa en marcha o como parte de una quiebra, liquidación o procedimiento similar. en el que los Datos personales que tenemos sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos.</Text>
            </ListaItem>
            <ListaItem>
            <Text><strong>Para otros fines</strong>: Podemos utilizar su información para otros fines, como el análisis de datos, la identificación de tendencias de uso, la determinación de la eficacia de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.</Text>
            </ListaItem>
            </Lista>
            <Text>Podemos compartir su información personal en las siguientes situaciones:</Text>
            <Lista>
            <ListaItem><strong>Con proveedores de servicios:</strong> Podemos compartir Su información personal con Proveedores de Servicios para monitorear y analizar el uso de nuestro Servicio, para contactarlo.</ListaItem>
            <ListaItem><strong>Para transmisiones de empresas:</strong> Podemos compartir o transferir Su información personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la Compañía, financiamiento o adquisición de todo o una parte de Nuestro negocio a otra compañía.</ListaItem>
            <ListaItem><strong>Con Afiliados:</strong> Podemos compartir Su información con Nuestros afiliados, en cuyo caso les exigiremos que respeten esta Política de Privacidad. Los afiliados incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que controlamos o que están bajo control común con nosotros.</ListaItem>
            <ListaItem><strong>Con socios comerciales:</strong> Podemos compartir Su información con Nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.</ListaItem>
            <ListaItem><strong>Con otros usuarios:</strong> cuando comparte información personal o interactúa de otra manera en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede distribuirse públicamente al exterior.</ListaItem>
            <ListaItem><strong>Con su consentimiento</strong>: Podemos divulgar su información personal para cualquier otro propósito con su consentimiento.</ListaItem>
            </Lista>
            <Subtitle>Retención de sus datos personales</Subtitle>
            <Text>La Compañía conservará sus Datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Conservaremos y utilizaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestras políticas y acuerdos legales.</Text>
            <Text>La Compañía también conservará los Datos de uso para fines de análisis interno. Los datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de nuestro servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</Text>
            <Subtitle>Transferencia de sus datos personales</Subtitle>
            <Text>Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de Su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de Su jurisdicción.</Text>
            <Text>Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa Su acuerdo con esa transferencia.</Text>
            <Text>La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus datos personales a una organización o país a menos que existan controles adecuados establecidos, incluida la seguridad de Sus datos y otra información personal.</Text>
            <Subtitle>Divulgación de sus datos personales</Subtitle>
            <Subtitleh3>Transacciones de negocios</Subtitleh3>
            <Text>Si la Compañía está involucrada en una fusión, adquisición o venta de activos, Sus Datos personales pueden transferirse. Le enviaremos un aviso antes de que sus Datos personales se transfieran y queden sujetos a una Política de privacidad diferente.</Text>
            <Subtitleh3>Cumplimiento de la ley</Subtitleh3>
            <Text>En determinadas circunstancias, es posible que se le solicite a la Compañía que divulgue sus Datos personales si así lo requiere la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).</Text>
            <Subtitleh3>Otros requisitos legales</Subtitleh3>
            <Text>La Compañía puede divulgar sus datos personales de buena fe cuando considere que esta acción es necesaria para lo siguiente:</Text>
            <Lista>
            <ListaItem>Cumplir con una obligación legal</ListaItem>
            <ListaItem>Proteger y defender los derechos o propiedad de la Compañía</ListaItem>
            <ListaItem>Prevenir o investigar posibles irregularidades en relación con el Servicio</ListaItem>
            <ListaItem>Proteger la seguridad personal de los Usuarios del Servicio o del público</ListaItem>
            <ListaItem>Protegerse contra la responsabilidad legal</ListaItem>
            </Lista>
            <Subtitle>Seguridad de sus datos personales</Subtitle>
            <Text>La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.</Text>
            <Subtitle>Privacidad de los niños</Subtitle>
            <Text>Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 13 años. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado Datos personales, por favor Contáctenos. Si nos damos cuenta de que hemos recopilado Datos personales de cualquier persona menor de 13 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de Nuestros servidores.</Text>
            <Text>Si necesitamos basarnos en el consentimiento como base legal para procesar su información y su país requiere el consentimiento de uno de sus padres, podemos solicitar el consentimiento de su padre antes de recopilar y usar esa información.</Text>
        </PrivacySection>
        <PrivacySection>
            <Heading>Enlaces a otros sitios web</Heading>
            <Text>Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.</Text>
            <Text>No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.</Text>
        </PrivacySection>
        <PrivacySection>
            <Heading>Cambios a esta Política de Privacidad</Heading>
            <Text>Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.</Text>
            <Text>Le informaremos por correo electrónico y/o un aviso destacado en Nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la &quot;Última actualización &quot; fecha en la parte superior de esta Política de privacidad.</Text>
            <Text>Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.</Text>
        </PrivacySection>
        <PrivacySection>
            <Heading>Contacta con nosotros</Heading>
            <Text>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:</Text>
            <Lista>
            <ListaItem>
            <Text>Por email: soporte@kusina.site</Text>
            </ListaItem>
            <ListaItem>
            <Text>Por número de teléfono: +51973834813</Text>
            </ListaItem>
            </Lista>
        </PrivacySection>

        </Container>
      </PrivacySec>
    </>
  )
}

export default PrivacyPolicy
