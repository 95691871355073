import React from 'react';
import GlobalStyle from './globalStyles';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Home from './pages/HomePage/Home';
import TermsAndPolicies from './pages/TermsAndPoliciesPage/TermsAndPolicies';
import ReactGA from 'react-ga4';

const TRACKING_ID='UA-239766981-1';
ReactGA.initialize(TRACKING_ID) 

function App() {

  return (
    <Router>
      <GlobalStyle />
      <Routes>
         <Route path="/" element={<Home/>} />
         {/*<Route path="/planes" element={<Pricing/>} />
         <Route path="/descarga" element={<Banner/>} />
         <Route path="/contacto" element={<Footer/>} />*/}
         <Route path="/privacy-policies-anfitriones" element={<TermsAndPolicies/>} />
      </Routes>
    </Router>
  );
}

export default App;
