import planbusiness from '../../../images/plan-business.png';
import planstarter from '../../../images/plan-starter.png';
export const MONTHLY_PLAN = [
  {
    id: "starter",
    icon: planstarter,
    plan: "Starter",
    cost: "S/. 0.00",
    length: "por mes",
    description: "Pensada para micro restaurantes",
    alert: "sin comprobantes",
    features: [
      "Proveedores, Almacenes y Suministros",
      "Categorías y Productos",
      "Conexión con impresoras portátiles",
      "Estadística de ventas, utilidades, comensales",
      "Soporte remoto",
      "2 cuentas de empleados",
      "10 GB almacenamiento",
      "Carta digital (próximamente)",
    ],
  },
  {
    id: "business",
    icon: planbusiness,
    plan: "Business",
    cost: "S/. 49.00",
    length: "por mes",
    description: "Pensada para pequeños restaurantes",
    alert: "comprobantes ilimitados",
    features: [
      "Proveedores, Almacenes y Suministros",
      "Categorías y Productos",
      "Conexión con impresoras portátiles",
      "Estadística de ventas, utilidades, comensales",
      "Prioridad de soporte remoto",
      "5 cuentas de empleados",
      "Sin límite de almacenamiento",
      "Boletas y facturas electrónicas ilimitadas",
      "Conexión con ordenes de Rappi y PedidosYa",
      "Carta digital (próximamente)",
    ],
  },
];
export const YEARLY_PLAN = [
  {
    id: "starter",
    icon: planstarter,
    plan: "Starter",
    cost: "S/. 0.00",
    length: "por año",
    description: "Pensada para micro restaurantes",
    alert: "sin comprobantes",
    features: [
      "Proveedores, Almacenes y Suministros",
      "Categorías y Productos",
      "Conexión con impresoras portátiles",
      "Estadística de ventas, utilidades, comensales",
      "Soporte remoto",
      "2 cuentas de empleados",
      "15 GB almacenamiento",
      "Carta digital (próximamente)",
    ],
  },
  {
    id: "business",
    icon: planbusiness,
    plan: "Business",
    cost: "S/. 490.00",
    length: "por año",
    description: "Pensada para pequeños restaurantes",
    alert: "comprobantes ilimitados",
    features: [
      "Proveedores, Almacenes y Suministros",
      "Categorías y Productos",
      "Conexión con impresoras portátiles",
      "Estadística de ventas, utilidades, comensales",
      "Prioridad de soporte remoto",
      "5 cuentas de empleados",
      "Sin límite de almacenamiento",
      "Boletas y facturas electrónicas ilimitadas",
      "Conexión con ordenes de Rappi y PedidosYa",
      "Carta digital (próximamente)",
    ],
  },
];
