import React from 'react';
import { TAPPrivacyPolicy } from '../../components';

const TermsAndPolicies = () => {
  return (
    <>
    <TAPPrivacyPolicy />
    </>
  )
}

export default TermsAndPolicies
