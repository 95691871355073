import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Button, Button2 } from '../../../globalStyles';
import logo from '../../../images/logo-kusina.png';
import ReactGA from 'react-ga4';
import {
  Nav,
  NavbarContainer,
  ImgWrapper,
  Img,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavLink
} from './Navbar.elements';

const Navbar = () => {

  /*G.A.*/
  const onClickLogin = () => {
    ReactGA.event({
      action: 'kusina_accion',
      category: 'gestion_producto_category',
      label: 'gestion_producto',
      value: '1'
    })
  }

  const onClickSignUp = () => {
    ReactGA.event({
      action: 'kusina_accion',
      category: 'sales_category',
      label: 'sales',
      value: '1'
    })
  }

  /**/

  const [click, setClick] = useState(false);
  const [setButton] = useState(true)
  const handleClick = () => setClick(!click);
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  };


  window.addEventListener('resize', showButton);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavbarContainer>
            <ImgWrapper>
              <Img src={logo} alt='Kusina Restaurantes' />
            </ImgWrapper>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes color='black' /> : <FaBars color='black' />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <a href="#planesSection" style={{ textDecoration: 'none', color: 'inherit' }}>
                <NavItem style={{ paddingTop: '30px', paddingRight: '6px' }}>
                  Planes
                </NavItem>
              </a>
              <NavItemBtn>
                <NavLink
                  href={
                    'https://kusina.site/auth/login'
                  }
                  rel='noopener noreferrer'
                  target='_blank'
                  aria-label='Whatsapp'
                >
                  <Button primary onClick={onClickLogin}>Iniciar sesión</Button>
                </NavLink>
              </NavItemBtn>
              <NavItemBtn>
                <NavLink
                  href={
                    'https://kusina.site/auth/sign-up'
                  }
                  rel='noopener noreferrer'
                  target='_blank'
                  aria-label='Whatsapp'
                >
                  <Button2 primary onClick={onClickSignUp}>Registrarse</Button2>
                </NavLink>
              </NavItemBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
